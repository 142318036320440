.App {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;

    .quizContainer {
        position: relative;
        background: #fff;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
        width: 800px;
        height: 600px;

        .header {
            width: 100%;
            height: 20%;
            background-image: linear-gradient(62deg, #EA9828 0%, #F9EF11 100%);
        }
        .main {
            width: 100%;
            height: 80%;

            .score {
                height: 100%;
                display: grid;
                place-items: center;
                
                p {
                    font-size: 40px;
                    font-weight: bold;
                }
            }
            button {
                display: block;
                margin: 30px auto 0 auto;
                padding: 10px 15px;
                border: 2px solid #EA9828;
                border-radius: 10px;
                background: transparent;
                font-size: 18px;
                text-transform: uppercase;
                color: #EA9828;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    background: #EA9828;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .App {
        .quizContainer {
            width: 100%;
            height: 100%;
        }
    }
}