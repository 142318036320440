.quiz {
    width: 75%;
    height: 350px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    background: #fff;
    margin: -30px auto 0 auto;
    padding: 30px;
    border-radius: 10px;

    p.grey {
        color: #B2C4CA;
    }
    p.questions {
        margin: 20px 0 30px 0;
        font-size: 20px;
    }

    .reponses {
        margin-top: 20px;

        p {
            margin: 10px 0;
            padding: 10px 5px;
            cursor: pointer;
            border-radius: 10px;

            span {
                font-size: 20px;
                font-weight: 600;
                margin-right: 20px;
                margin-left: 10px;
            }
        }
        p.active {
            background-image: linear-gradient(62deg, #EA9828 0%, #F9EF11 100%);
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
            color: #fff;

            span {
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .quiz {
        width: 95%;
        border-radius: 10px;
    }
}