$poppins: Poppins ,sans-serif;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $poppins;
}

body {
    background-color: #F2F3FB;
}

p, span {
    color: #6A6E6F;
    font-size: 18px;
}

